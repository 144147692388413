import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button, Checkbox, CircularProgress, styled, TextField,
} from "@material-ui/core";

import { Elements, CardElement, useElements } from "@stripe/react-stripe-js";
import MainContext from "./context/main/mainContext";

function getModalStyle() {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    display: "block",
    "overflow-y": "auto",
    "max-height": "80%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function StripeAddPaymentModal(props) {
  const mainContext = useContext(MainContext);
  const {
    user,
    stripe_customer,
    getCustomerPaymentMethods,
    addPaymentMethod,
  } = mainContext;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [isLoading, setIsLoading] = useState(false);

  const elements = useElements();

  const addCard = async () => {
    // Set loading to true
    // setIsLoading(true);

    // Submit element
    elements.submit();

    const res = await props.stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (res.error) {
      alert(res.error.message);
      setIsLoading(false);
      return;
    }

    // Add payment method
    addPaymentMethod({
      stripeCustomerId: props.stripeCustomerId,
      paymentMethodId: res.paymentMethod.id,
    }, (err) => {
      setIsLoading(false);
      if (err) {
        alert(err);
      } else {
        // Trigger update
        getCustomerPaymentMethods({
          stripeCustomerId: stripe_customer.id,
        }, (err) => {
          if (err) {
            alert(err);
          }
        });

        props.closeModal();
      }
    });

    // setIsLoading(false);
    // props.closeModal();
  };

  const body = (
    <div>
      {/* Orders */}
      <h3>Add Card</h3>
      <hr/>

      <CardElement />

      <br/>
      <br/>
      <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => addCard()}>
          Add Card
      </div>

    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
    >
      <div style={modalStyle} className={classes.paper}>
        {isLoading ? <CircularProgress /> : body }
      </div>
    </Modal>
  );
}
