import React, { useContext } from "react";
import MainContext from "./context/main/mainContext";
import InternalDemoTeamForm from "./InternalDemoTeamForm";
import StripeInvoice from "./StripeInvoice";

const StripeHome = () => {
  const mainContext = useContext(MainContext);
  const { stripe_subscription_extended } = mainContext;

  // Page to show
  let component = (
    <>
      <p>🤔</p>
    </>
  );

  if (stripe_subscription_extended) {
    component = (
      <>
        <StripeInvoice />
      </>
    );
  } else {
    component = (
      <>
        <InternalDemoTeamForm />
      </>
    );
  }

  return <div className="projectContainer">{component}</div>;
};

export default StripeHome;
