import { makeStyles, Tooltip } from "@material-ui/core";
import SvgMaker from "../utilities/SvgMaker";
import { svgs } from "../data/svgs";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "12px", // Customize the font size here
  },
}));

const ToolTip = ({ desc }) => {
  const classes = useStyles();
  return (
    <Tooltip placement="top" title={desc} classes={{ tooltip: classes.tooltip }}>
      <span style={{ width: 20, height: 20 }}>
        <SvgMaker name={svgs.infoInsights.name} height={20} width={20} />
      </span>
    </Tooltip>
  );
};

export default ToolTip;
