/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import SvgMaker from "../utilities/SvgMaker";

const useStyles = makeStyles((theme) => ({
  root: ({ width }) => ({
    position: "relative",
    width: width || "100%",
    paddingBottom: 24,
  }),
  inputWrapper: ({ error }) => ({
    width: "100%",
    height: 50,
    background: "#F7F7F7",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 24px",
    border: error?.state ? "1px solid #f5020f" : "none",
  }),
  selectWrapper: ({ error }) => ({
    position: "relative",
    width: "100%",
    height: 50,
    background: "#F7F7F7",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 24px",
    border: error?.state ? "1px solid #f5020f" : "none",
    cursor: "pointer",
  }),
  input: {
    width: "100%",
    border: "none",
    backgroundColor: "#F2F2F2",
    outline: "none",
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    "&:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7F7 inset !important",
    },
    "&:-webkit-autofill:hover": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7F7 inset !important",
    },
    "&:-webkit-autofill:focus": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7F7 inset !important",
    },
    "&:-webkit-autofill:active": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7F7 inset !important",
    },
    "&:disabled": {
      backgroundColor: "#cecdcd",
    },
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 8,
  },
  value: {
    fontSize: 13,
    fontWeight: 600,
  },
  optionsContainer: {
    position: "absolute",
    top: 50,
    left: 0,
    width: "100%",
    maxHeight: 220,
    overflow: "auto",
    zIndex: 11,
    backgroundColor: "#ffffff",
    boxShadow: "0px 12px 40px rgba(0, 0, 0, 0.2)",
    borderRadius: 10,
    "&::-webkit-scrollbar": {
      height: 10,
      width: 13,
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#adadad",
      borderRadius: 7,
      // height: 6,
      border: "4px solid rgba(0, 0, 0, 0)",
      backgroundClip: "padding-box",
      "&::-webkit-border-radius": 7,
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      "&::-webkit-box-shadow":
        "inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)",
    },
  },
  optionsWrapper: {
    padding: "10px 24px",
    display: "flex",
    flexDirection: "column",
  },
  option: {
    display: "inline-block",
    padding: 10,
    borderRadius: 10,
    fontSize: 13,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  },
}));

const SelectField = ({
  onChange, width, dataset, name, initialValue, error, label, styles = {},
}) => {
  const classes = useStyles({ width, error });
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState({});
  const [selectState, setSelectState] = useState(false);
  const inputRef = useRef();
  const [text, setText] = useState(
    dataset.find(({ code }) => code === initialValue).name || "United States",
  );
  const [data, setData] = useState(dataset);

  const onBlurInput = (event) => {
    if (!selectState) {
      setOpen(false);
    }

    if (selectValue.name) {
      setText(selectValue.name);
      onChange({ target: { name: selectValue.name, value: selectValue.code } });
    }
  };

  const changeHandler = (event) => {
    setText(event.target.value);
    setData(
      dataset.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase())),
    );
  };

  useEffect(() => {
    if (open) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <div className={classes.root} style={styles.root || {}}>
      <p className={classes.label}>{label}</p>
      <div className={classes.selectWrapper}>
        <input
          style={{ height: "100%" }}
          className={classes.input}
          name={name}
          value={text}
          onChange={changeHandler}
          onClick={(event) => {
            setOpen(true);
          }}
          onBlur={onBlurInput}
          autoComplete="off"
          ref={inputRef}
        />
        <div
          onMouseEnter={() => {
            setSelectState(true);
          }}
          onClick={(event) => {
            setOpen(!open);
          }}
          onMouseLeave={() => {
            setSelectState(false);
          }}
          style={open ? { transform: "rotate(180deg)" } : {}}
        >
          <SvgMaker name="svgs_arrowDown" fill="none" width={14} height={8} />
        </div>
        {open && (
          <div
            className={classes.optionsContainer}
            onMouseLeave={() => setSelectValue({ name: "", code: "" })}
          >
            <div data-testid="optionsWrapper" className={classes.optionsWrapper}>
              {data.map((item) => (
                <span
                  key={item.code}
                  className={classes.option}
                  onMouseEnter={() => {
                    setSelectValue(item);
                  }}
                >
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectField;
