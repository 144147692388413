// Global
export const LOADING = "LOADING";
export const END_LOADING = "END_LOADING";
export const LOGIN = "LOGIN";
export const STRIPE_CUSTOMER = "STRIPE_CUSTOMER";
export const STRIPE_SUBSCRIPTION_PREVIEW = "STRIPE_SUBSCRIPTION_PREVIEW";
export const STRIPE_SUBSCRIPTION_EXTENDED = "STRIPE_SUBSCRIPTION_EXTENDED";
export const STRIPE_INVOICE = "STRIPE_INVOICE";
export const SEARCH = "SEARCH";
export const CHANGE_TAB = "CHANGE_TAB";
export const POST_CREATE_TEAM = "POST_CREATE_TEAM";
export const MARK_INVOICE_SENT = "MARK_INVOICE_SENT";
export const DEMO_FLOW_DONE = "DEMO_FLOW_DONE";
export const GET_MEMBER = "GET_MEMBER";
export const GET_TEAM = "GET_TEAM";
export const STRIPE_PRICE_CONFIG = "STRIPE_PRICE_CONFIG";
export const POST_UPGRADE_TEAM = "POST_UPGRADE_TEAM";
export const POST_SET_EMAIL_STEP = "POST_SET_EMAIL_STEP";
export const SET_ENV = "SET_ENV";
export const STRIPE_CUTOMER_PAYMENT_METHODS = "STRIPE_CUTOMER_PAYMENT_METHODS";
export const GET_HR_CONNECTIONS = "GET_HR_CONNECTIONS";
export const GET_AD_GROUPS = "GET_AD_GROUPS";
export const GET_AD_GROUP_USERS = "GET_AD_GROUP_USERS";
export const UPDATE_HR_CONNECTION_SYNC_CONF = "UPDATE_HR_CONNECTION_SYNC_CONF";
export const GET_MS_AD_MAPPING_CONF = "GET_MS_AD_MAPPING_CONF";
export const POST_CREATE_DEMO_TEAM = "POST_CREATE_DEMO_TEAM";
