import React from "react";

const DemoTeamSummary = ({
  companyName,
  numberSeats,
  priceId,
  filteredPriceConfig,
  discountPercent,
  isPriceMonthly,
  isCustomPricing,
  pricePerSeatYearlyDollars,
  multiYearSub,
  multiYearSubYears,
  offerFreeTrial,
  trialDate,
}) => (
  <div className="stripeCustomerContainer">
    <h4>Summary</h4>
    <p>Company Name: {companyName}</p>
    <p>Number of Seats: {numberSeats}</p>
    <p>Plan: {filteredPriceConfig.find((config) => config.priceId === priceId)?.title}</p>
    <p>Discount: {discountPercent}%</p>
    {!isPriceMonthly && (
      <>
        <p>Custom Pricing: {isCustomPricing ? "Yes" : "No"}</p>
        {isCustomPricing && <p>Price Per Seat Per Year: ${pricePerSeatYearlyDollars}</p>}
        <p>Multi-Year Subscription: {multiYearSub ? `Yes (${multiYearSubYears} years)` : "No"}</p>
      </>
    )}
    <p>
      Free Trial:{" "}
      {offerFreeTrial ? `Yes (${isPriceMonthly ? "1 month" : `until ${trialDate}`})` : "No"}
    </p>
  </div>
);

export default DemoTeamSummary;
