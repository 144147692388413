import React from "react";
import { svgs } from "../data/svgs";

function SvgMaker({
  name, fill, width, height, strokeFill, position,
}) {
  const SVG = svgs[name];

  return <SVG fill={fill} width={width} height={height} strokeFill={strokeFill} position={position}/>;
}

export default SvgMaker;
